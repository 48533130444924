import merge from 'lodash.merge';
import Translations, { getCommonTranslations } from '@nm-utils-lib-web/translations';
import { getOrganisation, ORGANISATIONS } from '@nm-utils-lib-web/organisations';

import { nutmeg, ipbGBR } from '../languages';

export default new Translations({
  language: 'en',
  defaultNS: getOrganisation(),
  resources: {
    en: {
      default: {
        ...nutmeg,
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR),
      },
      [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: {
        ...nutmeg,
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR),
      },
      [ORGANISATIONS.IPB_GBR]: {
        ...merge({}, nutmeg, ipbGBR),
        ...getCommonTranslations(ORGANISATIONS.IPB_GBR),
      },
    },
  },
});
